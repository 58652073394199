/* eslint-disable */
import axios from 'axios';

const test = "https://t.api.geovisiondispatch.geovisiongroup.com";
const prod = "https://api.geovisiondispatch.com";
// const env = prod;
const env = test;


const apiBaseURL = `${env}/service/rest/routing`;

/**
 * BRINGO URL
 */
const test_bringo = "https://api-dev.heybringo.com";
const prod_bringo = "https://api.heybringo.com";
//const env = prod;

const env_bringo = test_bringo;
// const env_bringo = prod_bringo;
const apiBaseURL_bringo = `${env_bringo}/api/public`;


export const GET = url => {
    return axios.get(`${apiBaseURL}/${url}`)
        .then(response => {
            return response
        })
        .catch((error) => {
            console.log('Error', error.message);
        })

}




export const GET_BRINGO = url => {
    return axios.get(`${apiBaseURL_bringo}/${url}`)
        .then(response => {
            return response
        })
        .catch((error) => {
            console.log('Error', error.message);
        })

}

export const GET_BRINGO_DOC = url => {
    return `${apiBaseURL_bringo}/${url}`;
}


export const BRINGO_CALL = url => {
    return axios.get(`${apiBaseURL}/${url}`)
        .then(response => {
            return response
        })
        .catch((error) => {
            console.log('Error', error.message);
        })

}

// export const POST_BRINGO = (url, data) => {
//     return axios(`${apiBaseURL_bringo}/${url}`, {
//         method: 'POST',
//         //headers:{ 'Content-Type': 'application/json, charset=utf-8'},
//         data: data
//     });
// }




// export const POST = (url, data) => {
//     return axios.post(`${apiBaseURL}/${url}`, {
//         data: data
//     })
//         .then(function (response) {
//             console.log(response);
//             return response
//         })
//         .catch((error) => {
//             //console.log('Error', error.message);
//             alert('Error', error.message);
//         })

// }


export const POST = (url, data) => {
    return axios(`${apiBaseURL}/${url}`, {
        method: 'POST',
        //headers:{ 'Content-Type': 'application/json, charset=utf-8'},
        data: data
    });
}


/* eslint-disable */



